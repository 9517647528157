import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  Image,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,

} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import PhillaFun from "../Nav/BrandImg/logoatv.jpeg";
import { motion } from "framer-motion";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById("navbar");
      if (nav && window.scrollY > 0) {
        nav.classList.add("scrolled");
      } else if (nav) {
        nav.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 60; // Adjust the offset value as needed
      const elementPosition = element.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
    onClose(); // Close the drawer after clicking on a button
  };

  return (
    <Box
    // "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)"
      id="navbar"
      style={{
        background:
          "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
      }}
      px={20}
      py={3}
      position="fixed"
      top={0}
      width="100%"
      zIndex={999}
      transition="background-color 0.3s ease"
      className="navbar"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="xl" fontWeight="bold" color="white" ml="auto">
            <Image
              onClick={() => handleScroll("Home")}
              w={10}
              borderRadius={20}
              src={PhillaFun}
            />
          </Text>
         
          <Flex
            display={{ base: "none", md: "flex" }}
            fontWeight="100"
            ml="420"
            // margin="auto"
            // border="1px solid red"
            alignItems="center"
            overflowX="auto"
            whiteSpace="nowrap"
          >
             <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.1 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Home")}
            >
              Home
            </Button>
            </motion.div>
            <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.3 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("AboutSection")}
            >
              About
            </Button>
            </motion.div>
            <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.5 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("WorkSection")}
            >
              How it Works
            </Button>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 1.7 }}
              >
                
            <Menu >
            <MenuButton as={Button} style={{ fontFamily: "Nunito Sans" }} bg="" _hover="" color="#ffffff">
              Donate
            </MenuButton>
            <MenuList
            bg="black" _hover="red"
              style={{
                background:
                  "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
              }}
            >
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 1.7 }}
              >
                
                <MenuItem
                style={{
                  background:
                    "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",fontFamily: "Nunito Sans" 
                }}  fontWeight="500" color="white" as={Link} to="https://www.paypal.com/donate/?hosted_button_id=TAGZ46P4T39AJ
                " onClick={onClose}>
                
                  <Image src="https://zeevector.com/wp-content/uploads/PayPal-Symbol.png" alt="PayPal Logo" w={6} h={6} />Paypal
                </MenuItem>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 1.7 }}
              >
                
                <MenuItem
                _hover={{background:"blue"}}
                style={{
                  background:
                    "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",fontFamily: "Nunito Sans" 
                }}  fontWeight="500" color="white" as={Link} to="https://www.paypal.com/qrcodes/venmocs/204d9191-9584-4dd3-9ad5-2478abbbd950?created=1686103915" onClick={onClose}>
               <Image src="https://w7.pngwing.com/pngs/907/985/png-transparent-venmo-square-cash-payment-paypal-public-writer-blue-angle-text.png" alt="PayPal Logo" w={6} h={6} mr={1} />  Venmo
                </MenuItem>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 1.7 }}
              >
                
                <MenuItem style={{
                background:
                  "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",fontFamily: "Nunito Sans" 
              }}  fontWeight="500" color="white" _hover="red" as={Link} to="https://buy.stripe.com/5kA8zWguofg19LG289" onClick={onClose}>
              <Image src="https://image.pngaaa.com/749/2919749-middle.png" alt="PayPal Logo" w={6} h={6} mr={1} />  Stripe
                </MenuItem>
              </motion.div>
            </MenuList>
          </Menu>
          </motion.div>
            {/* <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.9 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Download")}
            >
              Demo Videos
            </Button>
            </motion.div> */}
          </Flex>
          
        </Flex>
       
        <Box display={{ base: "block", md: "none" }}>
          <Button onClick={onOpen} variant="ghost">
            <HamburgerIcon w={6} h={6} />
          </Button>
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay>
              <DrawerContent
                borderRadius={20}
                // h="40vh"
              
                style={{
                  background:
                    "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",width:"100%"
                }}
              >
                <DrawerCloseButton color="white" />
                <DrawerHeader color="yellow.400" fontWeight={600}>
                  <Image w={20} borderRadius="full" src={PhillaFun} />
                </DrawerHeader>
                <DrawerBody style={{
        background:
          "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
      }}>
                  <Flex direction="column">
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Home")}
                    >
                      Home
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("AboutSection")}
                    >
                      About
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("WorkSection")}
                    >
                      How it works
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Home")}
                    >
                      Donate
                    </Button>
                    {/* <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Faq")}
                    >
                      FAQs
                    </Button> */}
                    {/* <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Download")}
                    >
                      Demo Videos
                    </Button> */}
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;

