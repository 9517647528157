import React from "react";
import { Box, Text, Link, ChakraProvider } from "@chakra-ui/react";
import { useEffect } from 'react';
const Page = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <ChakraProvider>
      <Box
        maxW="full"
        mx="auto"
        p={4}
        bg="	#e9e9e9"
        style={{ fontFamily: "Nunito Sans" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="4">
          All The Virtue Terms of Service
        </Text>
        <Text mb="4">Date of Last Revision: Oct 4, 2023</Text>
        <Text mb="4">
          We reserve the right, at our sole discretion, to change or modify
          portions of these Terms of Service at any time and without notice.
          Your continued use of the All The Virtue Platform (the “Platform”)
          constitutes your acceptance of the terms. If you do not wish to accept
          the new Terms of Service, discontinue your use of the Services.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Acceptance of Terms
        </Text>
        <Text mb="4">
        By using the All The Virtue website or application ("Website"), you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our Website.

{" "}
          <Link color="blue.500" href="mailto:allthevirtue@gmail.com">
            allthevirtue@gmail.com
          </Link>
          .
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Eligibility
        </Text>
        <Text mb="4">
        You must be at least 18 years old and have the mental capacity to enter into and abide by these Terms of Service. Use of the Website by anyone under 18 is not authorized.
        </Text>
        
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Changes to Terms
        </Text>
        <Text mb="4">
        We reserve the right to make changes or updates to these Terms of Service at any time without notice. It is your responsibility to check for updates. Continued use of the Website constitutes acceptance of the most current version of these terms.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Purpose
        </Text>
        <Text mb="4">
        The Website provides educational information for informational purposes only. It is not a substitute for professional advice, and you should consult with the relevant professionals for your specific needs.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Voluntary Participation
        </Text>
        <Text mb="4">
        You voluntarily choose to use the Website and are responsible for your actions and outcomes. We are not liable for any harm, injuries, or negative consequences resulting from your use of the Website.


        </Text>
       
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Limitation of Liability
        </Text>
        <Text mb="4">
        We are not liable for any direct or indirect damages arising from your use of the Website or products/services purchased through it. You accept all risks associated with using the Website.
        </Text>
       
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Indemnification
        </Text>
        <Text mb="4">
        You agree to defend, indemnify, and hold us harmless from any claims or damages related to your use of the Website.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Accuracy
        </Text>
        <Text mb="4">
        While we strive for accuracy, we are not responsible for errors, omissions, or inaccuracies on the Website. You must provide accurate information when using our services.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Testimonials
        </Text>
        <Text mb="4">
        Testimonials on the Website do not guarantee the same results for all users. They represent the opinions of individual clients.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Affiliates and Endorsements
        </Text>
        <Text mb="4">
        We may promote or affiliate with other businesses, but we are not responsible for their products or services. Your decision to engage with them is your own.
        </Text>
       
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Warranties
        </Text>
        <Text mb="4">
        We make no warranties, express or implied, regarding the Website's fitness for a particular purpose or accuracy.
        </Text>
        
       
      </Box>
    </ChakraProvider>
  );
};

export default Page;
