import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import PhilaFun from "../Nav/BrandImg/logoatv.jpeg";


export default function About() {
  return (
    <Container style={{ overflowX: "visible" }} id="AboutSection" maxW={"7xl"}>
      
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
          lineHeight={"110%"}
        >
          {" "}
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            as={"span"}
            color={"#2F706D"}
        
            fontWeight="700"
          >
            About Us
          </Text>
        </Heading>
        <Stack spacing={6} direction={"row"}>
          {/* <Button
            px={12}
            py={9}
            lineHeight={"80%"}
            color="#DEC776"
            colorScheme={"#DEC776"}
           bg="#009BB5"
            // _hover={{ bg: 'orange.500' }}
          > */}
          <Box
       
       
          >
          <Image borderTopLeftRadius={30}  borderBottomRightRadius={30} width="130px" src={PhilaFun} />
          </Box>
      
            
          {/* </Button> */}
        </Stack>

        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color={"#2F706D"}
          textAlign="start"
          maxW={"4xl"}
        >
        All The Virtue is just that, a thoughtful way of implementing high merit ethics. Here we use a normative method of ethical mediation, providing virtuous decisions for avid couples desiring resolution for their disputes. In addition, we extend virtuous advice to the couples, inspiring them to move forward with one another in a virtuous manner.
<br /><br />
Goodness, fairness, integrity, honesty, self-control, righteousness, and compassion are just a few of the many virtues that we will be very partial to when deciding the outcome of couple disputes.
<br /><br />
Various elements of philosophy, notably Epistemology, Metaphysics, Ethics, and Logic will all be implemented into the virtuous type of responses that will decide a couple’s dispute.
<br /><br />
Please note: Couple disputes can vary in range of importance. The dispute can be something small and frivolous, or something of greater significance and substance. But as long as it is important to you, it is important to us. We hope that you have an open mind when submitting your couple disputes, and we hope your partner has an open mind as well.
        </Text>
        <Heading
          fontWeight={500}
          fontSize={{ base: "8xl", sm: "3xl", md: "3xl" }}
          lineHeight={"210%"}
        >
          {" "}
        </Heading>
      </Stack>
      <Text
        as={"span"}
        style={{ fontFamily: "Nunito Sans" }}
        fontWeight={700}
        fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
        ml="12px"
        color={"#2F706D"}
      >
        How it works
      </Text>
    
    </Container>
  );
}
