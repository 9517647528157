import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";

import dark from "../AfNav/ss.png";
import { motion } from "framer-motion";

export default function CallToActionWithVideo() {
  const scrollToSection = () => {
    // Find the section by its id
    const section = document.getElementById("WorkSection");

    // Check if the section exists
    if (section) {
      // Use smooth scrolling to scroll to the section
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
   
    <Container
      id="Home"
      paddingBottom="73px"
      maxW={"full"}
     
      marginTop="30px"
      backdropContrast="10%"
      backgroundImage={dark}
      // backgroundBlendMode="multiply" // Add blend mode for dim effect
      // filter="brightness(0.76)" // Adjust brightness for dim effect
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
    >
       <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
      <Stack
        align={"center"}
        // ml="15px"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            ml="12px"
            fontSize={{ base: "3xl", sm: "4xl", lg: "4xl" }}
          >
            <Text
              as={"span"}
              color="white"
              style={{ fontFamily: "Nunito Sans" }}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                color: "#FFFFFF",
                left: 0,
                zIndex: -1,
              }}
            >
             
              All The Virtue is just that, 
            </Text>
            <br />
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              as={"span"}
              color={"#FFFFFF"}
            >
           a thoughtful way of implementing 
            </Text>
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              as={"span"}
              color={"#FFFFFF"}
            >
             <br /> high merit ethics.
            </Text>
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            ml="12px"
            color={"#FFFFFF"}
          >
            On All The Virtue, we use a normative method of ethical mediation, providing virtuous decisions for avid couples desiring resolution for their disputes. In addition, we extend virtuous advice to the couples, inspiring them to move forward with one another in a virtuous manner.
            {/* At Philafun, we invite you to become a philanthropist and makes{" "}
            <br />
            lasting difference in the lives of those in need. By embracing the{" "}
            <br />
            power of giving, you can help transform communities,empower <br />
            individuals, and support vital causes across the globe */}
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              // rounded={'half'}
              ml="12px"
              size={"lg"}
              style={{
                background:
                  "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
              }}
              fontWeight={"normal"}
              px={16}
              py={0}
              colorScheme={"red"}
              color="white"
              _hover={{ bg: "white", color: "black" }}
              onClick={scrollToSection}
            >
              <Text>Get started</Text>
            </Button>
          </Stack>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            lineHeight={10}
            pb="10%"
            ml="12px"
            color={"#FFFFFF"}
          >
           Providing virtuous advice and decisions for avid 
{" "}
            <br />
            couples desiring resolution to their disputes.
{" "}
           <br/>
          
        
          </Text>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        ></Flex>
      </Stack>
      </motion.div>
    </Container>
   
  );
}

