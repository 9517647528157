import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import grouptwo from "../support/Simage/volunter.jpeg";

export default function SplitScreen() {
  return (
    <Stack minH={"40vh"} direction={{ base: "column", md: "row" }}>
      <Flex direction="column-reverse" flex={1}>
        <Image
          width="40.5%"
          ml="10%"
          mb="23px"
          mt={{ base: "20px" }}
          borderRadius="full"
          alt={"Login Image"}
          objectFit={"cover"}
          src={grouptwo}
        />
      </Flex>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
            style={{ fontFamily: "Nunito Sans", fontWeight: "600" }}
            fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}
          >
            <Text color="#2F706D" as={"span"} position={"relative"}>
              Purpose of our Website
            </Text>
            <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#2F706D"}
              as={"span"}
            >
              {/* passionate about */}
            </Text>{" "}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#323232"}
          >
            At 'All The Virtue' we foster ethical mediation for people,
            guiding them towards virtuous resolutions. We promote values like
            fairness, integrity, and compassion, drawing from philosophy. Our
            mission is to help people of all kinds, resolving disputes big or
            small. We inspire virtue and open-mindedness in relationships,
            prioritizing what matters to them. Join us in building stronger,
            more virtuous partnerships.
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
