import { Link } from "react-router-dom";
import {
  Box,
  Stack,
  HStack,
  VStack,
 
  Image,
  Text,
} from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";
import PhilaFun from "../Nav/BrandImg/logoatv.jpeg";
// import ab from "./footImg/ab.png";
import ae from "./footImg/ae.png";
import af from "./footImg/af.png";
import ad from "./footImg/ad.png";
import Gp from "../download/downImg/Gp.png";
import Ap from "../download/downImg/Ap.png";


const Footer = () => {
  return (
    <Box
      p={{ base: 5, md: 8 }}
      style={{
        background:
          "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
      }}
      maxW="full"
      marginInline="auto"
    >
      <Stack
        spacing={{ base: 8, md: 0 }}
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <HStack
          spacing={4}
          d={{ base: "none", sm: "flex" }}
          justifyContent={{ sm: "space-between", md: "normal" }}
        >
          <VStack
            style={{ fontFamily: "Nunito Sans" }}
            spacing={4}
            ml="10px"
            mb="20"
            alignItems="flex-start"
          >
            <Text fontSize="lg" color="#ffffff" fontWeight="bold">
              Quick Links
            </Text>
            <VStack
              style={{ fontFamily: "Nunito Sans" }}
              spacing={2}
              cursor="pointer"
              _hover="blue"
              alignItems="flex-start"
              color="#ffffff"
            >
              <CustomLink to="Home">Home</CustomLink>
              <CustomLink to="AboutSection">About us</CustomLink>
              <CustomLink to="WorkSection">How it Works</CustomLink>
              <CustomLink to="Home">Donate</CustomLink>
              {/* <CustomLink to="Faq">FAQs</CustomLink>
              <CustomLink to="Download">Demo Videos</CustomLink>
              <a href="https://apps.apple.com/app/philafun/id6448131825"><Image mt={8} width="65%" src={Ap} /></a> 
              <a href="https://play.google.com/store/apps/details?id=com.philafun.philafun"><Image width="65%" src={Gp} /></a>  */}
            </VStack>
          </VStack>
        </HStack>
        <Box maxW="708px">
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize="lg"
            fontWeight="bold"
            color="#ffffff"
          >
            About Us
          </Text>

          <Image mt="3%" w="100px" borderRadius="full" src={PhilaFun} alt="s" />

          <Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontSize="md"
          >
          All The Virtue is just that, a thoughtful way of implementing high merit ethics. Here we use a normative method of ethical mediation, providing virtuous decisions for avid couples desiring resolution for their disputes. In addition, we extend virtuous advice to the couples, inspiring them to move forward with one another in a virtuous manner.
            <br /> *Please note: Couple disputes can vary in range of importance. The dispute can be something small and frivolous, or something of greater significance and substance. But as long as it is important to you, it is important to us. We hope that you have an open mind when submitting your couple disputes, and we hope your partner has an open mind as well.
          </Text>
          {/* <Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontWeight={700}
            fontSize="md"
          >
            Get in touch with us at
          </Text> */}

          {/* <Stack flexDirection="row" mt={5} h="8" W="100%">
          
            <a href="https://www.instagram.com/philafunapp/">
              <Image h="8" src={ad} />
            </a>
            <a href="https://www.facebook.com/thephilafunapp">
              <Image h="8" src={ae} />
            </a>
            <a href="https://www.youtube.com/@philafun">
              <Image h="8" src={af} />
            </a>
          </Stack> */}
        </Box>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={3}
        justifyContent="space-between"
      >
        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color="#ffffff"
          fontSize="md"
          // ml="10px"
        >
          {" "}
          All rights reserved @All The Virtue
        </Text>
        <Stack spacing={2} direction={{ base: "column", md: "row" }}>
          <Text style={{ fontFamily: "Nunito Sans" }} mr="24px" color="#ffffff">
          <Link to="/termas-a">Terms of Service</Link> | <Link to="/privacy-p">Privacy Policy</Link> | <a>allthevirtue@gmail.com</a>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const CustomLink = ({ children, to, ...props }) => {
  return (
    <ScrollLink
      to={to}
      smooth={true}
      duration={500}
      offset={-50}
      spy={true}
      exact="true"
      activeClass="active"
      {...props}
    >
      {children}
    </ScrollLink>
  );
};

export default Footer;

