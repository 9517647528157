import React from "react";
import { Box, Text,  ChakraProvider } from "@chakra-ui/react";

const TermsOfServicePage = () => {
  return (
    <ChakraProvider>
      <Box
        maxW="full"
        mx="auto"
      
        p={4}
        bg="	#e9e9e9"
        style={{ fontFamily: "Nunito Sans" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="4" textAlign="center" textDecoration="underline">
          PRIVACY POLICY
        </Text>
<Text fontSize="xl" fontWeight="bold" mb="2">
Information We Collect
</Text>
        <Text mb="10">
        We may collect personal information, such as your name and email address, when you provide it voluntarily. We also collect non-personal information, such as your IP address and browsing history.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Use of Information
</Text>
        <Text mb="10">
        We use your personal information to provide our services, respond to inquiries, and send updates. We do not share your personal information with third parties, except as required by law.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Cookies and Tracking
</Text>
        <Text mb="10">
        We use cookies and similar technologies to collect non-personal information and improve your experience on the Website.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Security
</Text>
        <Text mb="10">
        We take reasonable steps to protect your information, but no method of transmission over the internet is entirely secure. We cannot guarantee the security of your data.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Third-Party Links
</Text>
        <Text mb="10">
        Our Website may contain links to third-party websites. We are not responsible for their privacy practices and encourage you to review their policies.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Children's Privacy
</Text>
        <Text mb="10">
        Our Website is not intended for children under 18, and we do not knowingly collect personal information from them.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Changes to Privacy Policy
</Text>
        <Text mb="10">
        We may update our Privacy Policy, and any changes will be posted on this page.
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default TermsOfServicePage;
