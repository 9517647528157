import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import groupone from "../work/Wimg/appuse.jpeg";

export default function SplitScreen() {
  return (
    <Stack
      id="WorkSection"
      minH={"41vh"}
      style={{
        background:
          "linear-gradient(270deg, hsla(189, 95%, 40%, 1) 0%, hsla(189, 87%, 34%, 1) 50%, hsla(189, 50%, 27%, 1) 100%)",
      }}
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
            <Text
              // ml={10}
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight="600"
              color="white"
              as={"span"}
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({
              //     base: "20%",
              //     md: "10%",
              //     sm: "5%",
              //   }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
              Application Use
            </Text>
            <br />{" "}
            {/* <Text
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={600}
              // ml={10}
              color={"#FFFFFF"}
              as={"span"}
            >
              philanthropists
            </Text>{" "} */}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#FFFFFF"}
          >
            The primary goal of the application is to assist people in making
            virtuous decisions when faced with disputes. This suggests that the
            application provides guidance and recommendations based on ethical
            and moral values to help people find resolutions that align with
            these values.
          </Text>
        </Stack>
      </Flex>
      <Flex direction="column-reverse" flex={1}>
        <Image
          style={{ marginLeft: "30px", borderRadius: "35px" }}
          width="48%"
          p={6}
          alt={"Login Image"}
          objectFit={"fill"}
          src={groupone}
        />
      </Flex>
    </Stack>
  );
}
